import { ApiId, HttpMethod, HttpResponse } from '@/api/model';

import { getApiUri } from '../api.utils';
import { fetchInterceptor } from './interceptor';

export const makeApiRequest = async <T>(
  api: ApiId,
  path: string,
  method: HttpMethod = 'GET',
  body?: unknown,
  additionalOptions?: RequestInit,
): Promise<HttpResponse<T>> => {
  const uri = getApiUri(api) + path;
  const headers = new Headers(additionalOptions?.headers || {});

  if (method !== 'GET' && !headers.has('Content-Type')) {
    headers.append('Content-Type', 'application/json');
  }

  const requestOptions: RequestInit = {
    credentials: 'include',
    headers,
    method,
    ...additionalOptions,
    body: method !== 'GET' && body ? JSON.stringify(body) : undefined,
  };
  const response = await fetchInterceptor(uri, requestOptions);
  const data: T = await response.json();

  return {
    data,
    headers: response.headers,
    status: response.status,
    statusText: response.statusText,
  };
};

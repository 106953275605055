import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Icon, Tooltip, Typography } from '@prenuvo/halo-foundation';
import { WarningTriangle } from '@prenuvo/halo-icon';

import { ReportOrgan } from '@/api';
import { useReportOrgan } from '@/api/queries';
import { JumpTo, ONCORAD } from '@/assets/icons';
import { FCT } from '@/i18n';
import {
  getNormalStatementsWithDefaultTrue,
  mergeDefaultAndCommonFindings,
} from '@/modules/reporting/shared/util';
import { NavListProps, NavListSubProps } from '@/shared/model';
import { Loading } from '@/shared/ui';
import { getOrgansWithHighScores, transformSnakeCaseToPascalCase } from '@/shared/util';
import {
  useDefaultFindingStore,
  useFindingStore,
  useFormStructureStore,
  useOrganValidationStore,
  useReportStore,
} from '@/store';

import './finalization-modal.css';

import { Normals } from './normals';

export type FinalizationModalProps = {
  setShowFinalizationModal: React.Dispatch<React.SetStateAction<boolean>>;
  showFinalizationModal: boolean;
};

export function FinalizationModal({
  setShowFinalizationModal,
  showFinalizationModal,
}: FinalizationModalProps) {
  const navigate = useNavigate();
  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { id: studyId } = useParams();
  const { setPreviewMode } = useReportStore();
  const { findings } = useFindingStore();
  const { defaultFindings } = useDefaultFindingStore();
  const { formStructure } = useFormStructureStore();
  const { organs } = useOrganValidationStore();
  const { report } = useReportStore();
  const { isLoading, reportOrgan } = useReportOrgan(report?.uuid || '');
  const organsWithHighScore = getOrgansWithHighScores(
    mergeDefaultAndCommonFindings(defaultFindings, findings),
  );
  const [normalsByOrgan, setNormalsByOrgan] = useState<Record<string, string[]>>({});
  const navigateToPreview = () => {
    navigate(`/reporting/${studyId}/preview`);
    setShowFinalizationModal(false);
    setPreviewMode(true);
  };

  useEffect(() => {
    if (reportOrgan && Array.isArray(reportOrgan) && reportOrgan.length > 0) {
      reportOrgan.forEach((normals: ReportOrgan) => {
        setNormalsByOrgan((prev) => ({
          ...prev,
          [normals.organ]: getNormalStatementsWithDefaultTrue(normals as ReportOrgan),
        }));
      });
    }
  }, [reportOrgan]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollbarRef.current) {
        const { clientHeight, scrollHeight, scrollTop } = scrollbarRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setIsButtonDisabled(false);
        }
      }
    };

    const scrollbarElement = scrollbarRef.current;

    if (scrollbarElement) {
      handleScroll();
      scrollbarElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollbarElement) {
        scrollbarElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollbarRef.current]);

  const isNormalExits = (organ: string) =>
    !organs[organ].done && normalsByOrgan[organ] && normalsByOrgan[organ].length > 0;

  const navigateTo = (organKey: string) => {
    const path = `/reporting/${studyId}/entry/organ/${organKey.replace(/_/g, '-')}`;

    setShowFinalizationModal(false);
    navigate(path);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>, organKey: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigateTo(organKey);
    }
  };

  if (isLoading) {
    return (
      <div className="fixed z-50 flex h-screen w-full items-center justify-center bg-stone-900/50 dark:bg-stone-900/50">
        <Loading />
      </div>
    );
  }

  const renderOrgan = (organData: NavListProps | NavListSubProps) => (
    <div key={organData.key} className="flex border-b px-2 py-4">
      <div>
        <div className="flex flex-row">
          {'title' in organData ? organData.title : organData.value}
          {organsWithHighScore[organData.key] && (
            <span className="ml-2">
              <img alt="ONCORAD" src={ONCORAD} />
            </span>
          )}
        </div>
        {organsWithHighScore[organData.key]?.map((finding) => (
          <div
            key={finding.conditionTemplate.condition}
            className="flex flex-row items-center pt-2 text-orange-400 dark:text-orange-400"
          >
            <Icon
              aria-label={FCT.FINALIZATION_MODAL.ORGAN.WARNING}
              className="mr-[12px] fill-orange-400"
              size="sm"
              source={WarningTriangle}
            />
            {transformSnakeCaseToPascalCase(finding.conditionTemplate.condition!)} - Finding marked
            as
            {finding.renderedSummary.oncoradsScore >= 4 &&
              ` ONCO-${finding.renderedSummary.oncoradsScore}`}
            {finding.renderedSummary.csdScore >= 4 &&
              finding.renderedSummary.oncoradsScore >= 4 &&
              ','}
            {finding.renderedSummary.csdScore >= 4 && ` CSD-${finding.renderedSummary.csdScore}`}
          </div>
        ))}
        {!organsWithHighScore[organData.key] && isNormalExits(organData.key) && (
          <Normals normals={normalsByOrgan[organData.key]} />
        )}
      </div>
      <div className="ml-auto mr-4 flex items-center">
        <Tooltip
          className="bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50"
          content={FCT.FINALIZATION_MODAL.ORGAN.JUMP_TO_ORGAN}
          side="bottom"
        >
          <span
            data-testid={`jump-to-${organData.key}`}
            onClick={() => navigateTo(organData.key)}
            onKeyDown={(event) => handleKeyDown(event, organData.key)}
            role="button"
            tabIndex={0}
          >
            <img
              alt={FCT.FINALIZATION_MODAL.ORGAN.JUMP_TO}
              className="cursor-pointer opacity-60 hover:opacity-100"
              src={JumpTo}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );

  return (
    <Dialog
      className={{
        content: 'h-auto w-[1024px] max-w-max',
        header:
          'items-center bg-stone-950  p-5 text-lg text-neutral-50 dark:bg-stone-950 dark:text-neutral-50',
        title: 'text-md md:text-md mr-10 font-normal',
      }}
      onOpenChange={(value) => setShowFinalizationModal(value)}
      open={showFinalizationModal}
      showClose
      title={FCT.FINALIZATION_MODAL.DIALOG.TITLE}
    >
      <div className="flex h-auto w-full flex-col rounded-b-[24px] bg-stone-950 p-5 pt-0 dark:bg-stone-950">
        <div className="custom-scrollbar max-h-[500px] overflow-y-auto" ref={scrollbarRef}>
          <div>
            {formStructure?.structure.map((organData) => {
              if (isNormalExits(organData.key) || organsWithHighScore[organData.key]) {
                return renderOrgan(organData);
              }

              if (organData?.sub) {
                return organData.sub.map((subOrganData) => {
                  if (isNormalExits(subOrganData.key) || organsWithHighScore[subOrganData.key]) {
                    return renderOrgan(subOrganData);
                  }

                  return null;
                });
              }

              return null;
            })}
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end">
          <Typography as="label" className="mr-4 text-neutral-400 dark:text-neutral-400">
            {FCT.FINALIZATION_MODAL.DIALOG.ACTION.DESCRIPTION}
          </Typography>
          <Button
            data-testid="finalization-preview-report"
            disabled={isButtonDisabled}
            onClick={navigateToPreview}
          >
            {FCT.FINALIZATION_MODAL.DIALOG.ACTION.PREVIEW_REPORT}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

import { Finding, OnDemandConditionsData } from '@/api';

import { SPONDYLOARTHROPATHY_CONDITIONS } from '../constant';

export const mergeOrgansAndOnDemandConditions = (
  organs: { [key: string]: Finding[] },
  onDemandConditions: {
    [key: string]: OnDemandConditionsData[];
  },
) =>
  Object.entries(onDemandConditions).reduce(
    (updatedOrgansData, [key, conditions]) => {
      const updatedOrgans = { ...updatedOrgansData };

      updatedOrgans[key] = conditions.reduce((organData, condition) => {
        const updatedOrganData = [...organData];

        if (condition.renderedSummary) {
          const updatedData = {
            conditionTemplate: { organ: key },
            error: null,
            observation: '',
            progress: false,
            renderedSummary: condition.renderedSummary,
          };

          const index = updatedOrganData.findIndex(
            (organ) => organ.renderedSummary.uuid === condition.renderedSummary!.uuid,
          );

          if (index !== -1) {
            updatedOrganData[index] = updatedData;
          } else {
            updatedOrganData.push(updatedData);
          }
        }

        return updatedOrganData;
      }, updatedOrgans[key] || []);

      return updatedOrgans;
    },
    { ...organs },
  );

export const sortAndGroupFindings = (findings: Finding[]) => {
  const spondyloarthropathyFindings: Finding[] = [];
  const otherFindings: Finding[] = [];
  let startIndex = 0;
  let indexUpdated = false;

  findings.forEach((finding, index) => {
    if (SPONDYLOARTHROPATHY_CONDITIONS.includes(finding.conditionTemplate.condition!)) {
      if (!indexUpdated) {
        startIndex = index;
        indexUpdated = true;
      }

      spondyloarthropathyFindings.push(finding);
    } else {
      otherFindings.push(finding);
    }
  });

  spondyloarthropathyFindings.sort(
    (a, b) =>
      SPONDYLOARTHROPATHY_CONDITIONS.indexOf(a.conditionTemplate.condition!) -
      SPONDYLOARTHROPATHY_CONDITIONS.indexOf(b.conditionTemplate.condition!),
  );

  if (spondyloarthropathyFindings.length > 0) {
    const updatedFindings = [
      ...otherFindings.slice(0, startIndex),
      ...spondyloarthropathyFindings,
      ...otherFindings.slice(startIndex),
    ];

    return updatedFindings;
  }

  return otherFindings;
};

export const mergeDefaultAndCommonFindings = (
  defaultFindings: { [key: string]: Finding[] },
  commonFindings: { [key: string]: Finding[] },
) => {
  const result = { ...defaultFindings };

  Object.entries(commonFindings).forEach(([key, findings]) => {
    let updatedFindings = [...findings];

    if (key === 'spine') {
      updatedFindings = sortAndGroupFindings(updatedFindings);
    }

    if (!result[key]) {
      result[key] = updatedFindings;
    } else {
      result[key] = [...result[key], ...updatedFindings];
    }
  });

  return result;
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import { UserError, UserResponse } from '@/api/model';

const KEY = {
  GET_USER: 'GET_USER',
} as const;

const request = {
  getUser: async (signal?: AbortSignal) => {
    const { data } = await fetcher.PII.get<UserResponse>(ENDPOINTS.PII.USER, { signal });

    return data;
  },
};

const query = {
  useGetUser: (config?: UseQueryOptions<UserResponse, UserError>) =>
    useQuery<UserResponse, UserError>({
      queryFn: ({ signal }) => request.getUser(signal),
      queryKey: [KEY.GET_USER],
      ...config,
    }),
};

export const user = {
  KEY,
  query,
  request,
};

import { create } from 'zustand';

import { OrganValidationState, OrganValidationStatus } from './use-organ-validation.model';

export const useOrganValidationStore = create<OrganValidationState>((set, get) => ({
  clearAllStatus: () => {
    set({
      organs: {},
    });
  },
  getStatus: (organ) => get().organs[organ],
  organs: {},
  setStatus: (organ: string, status: OrganValidationStatus) => {
    set((state) => ({
      organs: {
        ...state.organs,
        [organ]: status,
      },
    }));
  },
}));

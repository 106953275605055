import { ApiId } from '@/api/model';
import { CONFIG } from '@/core/config';

export const getApiUri = (apiId: ApiId): string => {
  const hosts = {
    APPS: CONFIG.APPS_SERVER,
    FCT: CONFIG.FCT_SERVER,
    HI: CONFIG.HI_SERVER,
    PII: CONFIG.PII_SERVER,
  };

  return hosts[apiId];
};

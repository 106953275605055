import { FCT } from '@/i18n';

import {
  AboutType,
  AboutTypeOption,
  CommonStyles,
  DescriptionGuides,
  IssueType,
  IssueTypeOption,
} from './report-issue.model';

export const COMMON_STYLES = (isRequired?: boolean): CommonStyles => ({
  input: `h-12 w-full rounded-lg bg-neutral-900 dark:bg-neutral-900 ${isRequired ? 'border-red-500' : 'border-0'}`,
  select: {
    content: 'bg-neutral-900 dark:bg-neutral-900',
    trigger: `h-12 dark:bg-neutral-900 ${isRequired ? 'border-red-500' : 'border-0'}`,
  },
});

export const ISSUE_TYPES: IssueTypeOption[] = [
  { value: IssueType.REPORT_ISSUE },
  { value: IssueType.SUGGEST_IMPROVEMENT },
];

export const ABOUT_TYPES: AboutTypeOption[] = [
  { value: AboutType.PATIENT_HISTORY },
  { value: AboutType.ORGANS },
  { value: AboutType.KEY_IMAGES },
  { value: AboutType.FINAL_IMPRESSIONS },
  { value: AboutType.REPORT_PREVIEW },
  { value: AboutType.SECURITY },
  { value: AboutType.GENERAL_SYSTEM_USABILITY },
  { value: AboutType.OTHER },
];

export const DESCRIPTION_GUIDES: DescriptionGuides = {
  [IssueType.REPORT_ISSUE]: {
    items: [
      FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.REPORT_ISSUE.EXPLAIN,
      FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.REPORT_ISSUE.DESCRIBE,
      FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.REPORT_ISSUE.SHARE,
    ],
    title: FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.REPORT_ISSUE.TITLE,
  },
  [IssueType.SUGGEST_IMPROVEMENT]: {
    items: [
      FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.SUGGEST_IMPROVEMENT.DESCRIBE,
      FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.SUGGEST_IMPROVEMENT.SHARE,
      FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.SUGGEST_IMPROVEMENT.IF,
    ],
    title: FCT.REPORTING.REPORT_ISSUE.ISSUE_TYPE.SUGGEST_IMPROVEMENT.TITLE,
  },
};

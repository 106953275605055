import { ReactNode, useMemo } from 'react';

import { User } from '@/api';

import { UserContext } from './user.context';
import { UserState } from './user.model';

export interface UserProviderProps {
  children: ReactNode;
  user: User;
}

export function UserProvider({ children, user }: UserProviderProps) {
  const value: UserState = useMemo(() => ({ user }), [user]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

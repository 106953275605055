import { FormStructure, Organ } from '@/api';
import { FORM_STRUCTURE_FULL_LIST } from '@/api/mocks/constants';

export const processFormStructure = (
  formStructure: { data: FormStructure[] },
  patientGender: string,
): FormStructure => {
  const highestFormStructuredVersion = formStructure.data.reduce((max, current) =>
    max.version > current.version ? max : current,
  );

  const missingOrgans = FORM_STRUCTURE_FULL_LIST.filter(
    (organ) =>
      !highestFormStructuredVersion.structure.some((organData) => organData.key === organ.key),
  );

  const genderSpecificOrgans = ['prostate', 'breasts', 'uterus', 'ovaries'];

  const updatedStructureData = highestFormStructuredVersion.structure
    .map((organ) => {
      if (genderSpecificOrgans.includes(organ.key.toLowerCase())) {
        if (patientGender === 'male') {
          return organ.key.toLowerCase() === 'prostate' ? organ : null;
        }

        return organ.key.toLowerCase() !== 'prostate' ? organ : null;
      }

      return organ;
    })
    .filter(Boolean)
    .concat(missingOrgans.map((organ) => ({ ...organ, disabled: true })));

  const updatedStructureDataForSub = updatedStructureData.map((organ) => {
    if (organ?.disabled && organ?.sub) {
      return {
        ...organ,
        sub: organ.sub.map((subOrgan) => ({ ...subOrgan, disabled: true })),
      };
    }

    return organ;
  });

  return {
    ...highestFormStructuredVersion,
    structure: updatedStructureDataForSub as Organ[],
  };
};

import { Typography } from '@prenuvo/halo-foundation';

import { IssueType } from '../report-issue.model';

type CommonLabelProps = {
  isRequired?: boolean;
  issueType?: IssueType;
  labelText: string;
  showErrors: boolean;
  title: string;
};

function ReportCommonLabel({
  isRequired,
  issueType,
  labelText,
  showErrors,
  title,
}: CommonLabelProps) {
  return (
    <Typography
      as="label"
      className={`${
        (showErrors && !title && labelText === 'Title') ||
        (showErrors && !issueType && labelText === 'What would you like to do?')
          ? 'text-red-500 dark:text-red-500'
          : 'text-neutral-400 dark:text-neutral-400'
      }`}
      variant="paragraph-sm"
    >
      {labelText}
      {isRequired && <span className="ml-1 text-red-500 after:content-['*'] dark:text-red-500" />}
    </Typography>
  );
}

export { ReportCommonLabel };

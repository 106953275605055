export const CONFIG = {
  APPS_SERVER,
  BASE_NAME: '/reporting',
  BASE_URL: import.meta.env.BASE_URL,
  DATADOG: {
    APP_ID: DD_APP_ID,
    SUBDOMAIN_TRACKING: true,
    TOKEN: DD_TOKEN,
    USE_SECURE_SESSION: true,
  },
  ENV: import.meta.env.MODE,
  FCT_SERVER,
  HI_SERVER,
  NAME: APP_NAME,
  PII_SERVER,
  VERSION: APP_VERSION,
} as const;

export const BASE_API_URL = '/api/v1' as const;

import { Typography } from '@prenuvo/halo-foundation';

import { useReportIssueContext } from '../report-context';
import { DESCRIPTION_GUIDES } from '../report-issue.constant';

export function ReportDescriptionGuide() {
  const { issueType } = useReportIssueContext();

  const guide = DESCRIPTION_GUIDES[issueType as keyof typeof DESCRIPTION_GUIDES];

  if (!guide) {
    return null;
  }

  return (
    <div>
      <Typography className="mb-2 font-semibold" variant="paragraph-md">
        {guide.title}
      </Typography>
      <ul className="list-disc space-y-1 px-4">
        {guide.items.map((item) => (
          <li key={item}>
            <Typography variant="paragraph-sm">{item}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
}

import { Dialog } from '@prenuvo/halo-foundation';

import { useReportIssueContext } from '../report-context';

export type ReportIssueModalProps = {
  children: React.ReactNode;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  title: string;
};

function ReportIssueModal({ children, onOpenChange, open, title }: ReportIssueModalProps) {
  const { resetForm } = useReportIssueContext();

  return (
    <Dialog
      className={{
        content: 'h-auto w-[824px] max-w-max overflow-y-auto',
        header:
          'items-center bg-stone-950 p-5 text-lg text-neutral-50 dark:bg-stone-950 dark:text-neutral-50',
        title: 'text-md md:text-md mr-10 font-normal',
      }}
      onOpenChange={() => {
        onOpenChange(false);
        resetForm();
      }}
      open={open}
      title={title}
    >
      <div className="w-[824px] bg-stone-950 p-5 dark:bg-stone-950">{children}</div>
    </Dialog>
  );
}

export { ReportIssueModal };

import { ReactNode } from 'react';

import { api } from '@/api';
import { Loading } from '@/shared/ui';

import { UserProvider } from './user.provider';

interface UserStateProps {
  children: ReactNode;
}

export function UserState({ children }: UserStateProps) {
  const { data, error, isError, isLoading } = api.pii.user.query.useGetUser();

  if (isLoading) {
    return <Loading />;
  }

  if ((error && isError) || data === undefined) {
    // Render AuthGuard to redirect to login
    return children;
  }

  return <UserProvider user={data.user}>{children}</UserProvider>;
}

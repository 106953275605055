import { create } from 'zustand';

import { OnDemandConditionsData } from '@/api';

import { OnDemandConditionState } from './on-demand-condition.model';

export const useOnDemandConditionsStore = create<OnDemandConditionState>((set, get) => ({
  getOnDemandConditions: (organ: string) => get().onDemandConditions[organ] || [],
  onDemandConditions: {},
  setOnDemandConditions: (organ: string, onDemandConditions: OnDemandConditionsData[]) => {
    set((state) => ({
      onDemandConditions: {
        ...state.onDemandConditions,
        [organ]: [...(state.onDemandConditions[organ] ?? []), ...onDemandConditions],
      },
    }));
  },
  updateOnDemandCondition: (
    organ: string,
    condition: string,
    onDemandCondition: OnDemandConditionsData,
  ) => {
    set((state) => {
      const organConditions = state.onDemandConditions[organ] || [];
      const index = organConditions.findIndex((c) => c.condition === condition);

      if (index > -1) {
        organConditions[index] = onDemandCondition;
      }

      return {
        onDemandConditions: {
          ...state.onDemandConditions,
          [organ]: organConditions,
        },
      };
    });
  },
}));

import { FunctionComponent, Suspense } from 'react';

import { Loading } from './loading';

export function LazyLoad(Component: FunctionComponent) {
  const LoadableComponent = function LoadableComponent(props: JSX.IntrinsicAttributes) {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };

  return LoadableComponent;
}

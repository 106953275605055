// Avoid dependency on @/core/auth/auth-context/auth.context.ts
import { useAuthStore } from '../../store/use-auth-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- error can be anything
export const errorInterceptor = (error: any) => {
  const { setIsAuthenticated } = useAuthStore.getState();

  const status = error?.response?.status;

  if ([401, 403].includes(status)) {
    setIsAuthenticated(false);

    return Promise.reject(new Error(`Request failed with status ${status}`));
  }

  return Promise.reject(
    new Error(`Network or other error when requesting ${error?.config?.url}:`, error),
  );
};

import { ConditionTemplate, Finding, FindingsByOrgan, RenderedSummary } from '@/api';

import { NavListProps } from '../model';

export const groupFindingsByOrgan = (data: RenderedSummary[]): { [key: string]: Finding[] } => {
  const groupedData: { [key: string]: Finding[] } = {};

  data.forEach((item) => {
    if (item.reportFindings.length === 0) {
      return;
    }

    const organ = item?.reportFindings[0]?.template?.organ || '';

    if (!groupedData[organ]) {
      groupedData[organ] = [];
    }

    const renderedSummaryData: Finding = {
      conditionTemplate: {
        condition: item.reportFindings[0].template?.condition,
        conditionDetail: item.reportFindings[0].template?.conditionDetail,
        normalStatementOutcome: item.reportFindings[0].template?.normalStatementOutcome,
        organ,
        uuid: item.reportFindings[0].template?.uuid,
      },
      renderedSummary: {
        actionDetails: item.actionDetails,
        actionSummary: item.actionSummary,
        csdScore: item.csdScore,
        findingDetails: item.findingDetails,
        findingSummary: item.findingSummary,
        keyImages: item.keyImages,
        newFinding: item.newFinding,
        oncoradsScore: item.oncoradsScore,
        reportFindings: item.reportFindings,
        reportFindingsUuids: item.reportFindingsUuids,
        reportUuid: item.reportUuid,
        status: '',
        uuid: item.uuid,
      },
    };

    groupedData[organ].push(renderedSummaryData);
  });

  return groupedData;
};

export const categorizeFindings = (
  findings: Finding[],
): { commonFindings: Finding[]; defaultFindings: Finding[] } => {
  const defaultFindings: Finding[] = [];
  const commonFindings: Finding[] = [];

  findings.forEach((finding) => {
    if (finding.conditionTemplate.condition?.startsWith('default_question')) {
      defaultFindings.push(finding);
    } else {
      commonFindings.push(finding);
    }
  });

  return { commonFindings, defaultFindings };
};

export const getOrgansWithHighScores = (data: {
  [key: string]: Finding[];
}): { [key: string]: Finding[] } => {
  const result: { [key: string]: Finding[] } = {};

  Object.keys(data).forEach((key) => {
    const organData = data[key];

    organData.forEach((item) => {
      const { csdScore, oncoradsScore } = item.renderedSummary;

      if (oncoradsScore === 4 || oncoradsScore === 5 || csdScore === 4 || csdScore === 5) {
        result[key] = [...(result[key] ?? []), item];
      }
    });
  });

  return result;
};

export const getConditionTemplates = (
  findings: FindingsByOrgan,
): {
  [key: string]: ConditionTemplate[];
} =>
  Object.entries(findings).reduce<{ [key: string]: ConditionTemplate[] }>(
    (acc, [organ, organFindings]) => {
      if (organ === 'spine') {
        acc[organ] = organFindings
          .map((finding) => finding.conditionTemplate as ConditionTemplate)
          .filter((finding) => finding.condition !== 'Spondyloarthropathy');
      } else {
        acc[organ] = organFindings.map((finding) => finding.conditionTemplate as ConditionTemplate);
      }

      return acc;
    },
    {},
  );

export const mergeFindings = (
  navList: NavListProps[],
  defaultFindings: FindingsByOrgan,
  findings: FindingsByOrgan,
): FindingsByOrgan =>
  navList.reduce((acc, navData) => {
    const organKey = navData.key;
    const organFindings = findings[organKey] || [];
    const defaultOrganFindings = defaultFindings[organKey] || [];

    acc[organKey] = [...defaultOrganFindings, ...organFindings];

    if (navData.sub?.length) {
      navData.sub.forEach((subOrgan) => {
        const subOrganKey = subOrgan.key;
        const subOrganFindings = findings[subOrganKey] || [];
        const defaultSubOrganFindings = defaultFindings[subOrganKey] || [];

        acc[subOrganKey] = [...defaultSubOrganFindings, ...subOrganFindings].map((finding) => ({
          ...finding,
          parentOrgan: organKey,
        }));
      });
    }

    return acc;
  }, {} as FindingsByOrgan);

import { ReportIssueProvider } from './report-context';
import { ReportIssueProps } from './report-issue.model';
import { ReportIssueContent } from './report-issue-content.component';

function ReportIssue({ buttonSize = 'sm' }: ReportIssueProps) {
  return (
    <ReportIssueProvider>
      <ReportIssueContent buttonSize={buttonSize} />
    </ReportIssueProvider>
  );
}

export { ReportIssue };

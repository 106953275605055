import { Alert, Button, Spinner, Typography } from '@prenuvo/halo-foundation';

import { FCT } from '@/i18n';

import { useReportIssueContext } from './report-context';
import { ReportDescriptionGuide, ReportFormActions, ReportFormFields } from './report-form';
import { ReportIssueProps } from './report-issue.model';
import { ReportIssueModal } from './report-issue-modal';

function ReportIssueContent({ buttonSize = 'sm' }: ReportIssueProps) {
  const {
    isReportIssueError,
    isReportIssuePending,
    isReportIssueSuccess,
    setShowReportIssue,
    showReportIssue,
  } = useReportIssueContext();

  return (
    <>
      <Button
        data-testid="report-issue-button"
        onClick={() => setShowReportIssue(true)}
        size={buttonSize}
        variant="text"
      >
        {FCT.REPORTING.REPORT_ISSUE.ACTIONS.REPORT_ISSUE}
      </Button>
      <ReportIssueModal
        onOpenChange={setShowReportIssue}
        open={showReportIssue}
        title={FCT.REPORTING.REPORT_ISSUE.TITLE}
      >
        {isReportIssuePending ? (
          <div className="flex h-[510px] items-center justify-center">
            <Spinner size="sm" variant="white" />
          </div>
        ) : (
          <div className="space-y-4" data-testid="report-issue-form">
            {isReportIssueSuccess ? (
              <Typography className="text-center" variant="paragraph-md">
                {FCT.REPORTING.REPORT_ISSUE.SUCCESS}
              </Typography>
            ) : (
              <>
                {isReportIssueError && (
                  <Alert
                    className={{
                      description: 'px-2',
                      root: 'm-0',
                      title: 'px-2',
                    }}
                    description={FCT.REPORTING.REPORT_ISSUE.ERROR.DESCRIPTION}
                    title={FCT.REPORTING.REPORT_ISSUE.ERROR.TITLE}
                    variant="error"
                  />
                )}
                <ReportFormFields />
                <ReportDescriptionGuide />
              </>
            )}
            <ReportFormActions />
          </div>
        )}
      </ReportIssueModal>
    </>
  );
}

export { ReportIssueContent };

import { useMutation } from '@tanstack/react-query';

import { ENDPOINTS } from '@/api/endpoints';
import { fetcher } from '@/api/fetcher';
import { ReportIssueError, ReportIssueRequest, ReportIssueResponse } from '@/api/model';

const request = {
  postReportIssue: async ({ payload }: ReportIssueRequest) => {
    const { data } = await fetcher.FCT.post<ReportIssueResponse>(
      ENDPOINTS.FCT.APPS.REPORT_ISSUE,
      payload,
    );

    return data;
  },
};

const mutation = {
  usePostReportIssue: () =>
    useMutation<ReportIssueResponse, ReportIssueError, ReportIssueRequest>({
      mutationFn: request.postReportIssue,
    }),
};

export const reportIssue = {
  mutation,
  request,
};

import { Navigate, useParams } from 'react-router-dom';

export function DefaultStudyWrapper({ children }: { children: React.ReactNode }) {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return <Navigate replace to="/404" />;
  }

  return children;
}

import { useState } from 'react';
import { Icon, Typography } from '@prenuvo/halo-foundation';
import { Checkmark } from '@prenuvo/halo-icon';

import { FCT } from '@/i18n';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/shared/ui';

export type NormalsProps = {
  normals: string[];
};

export function Normals({ normals }: NormalsProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion
      aria-label={FCT.FINALIZATION_MODAL.NORMALS.LABEL}
      className="m-0"
      collapsible
      data-testid="normals-accordion"
      onValueChange={(value: string) => setIsOpen(value !== '')}
      type="single"
      value={isOpen ? 'normal' : ''}
    >
      <AccordionItem
        aria-labelledby="normals-trigger"
        className="mb-0 border-0"
        data-testid="normals-accordion-item"
        value="normal"
      >
        <AccordionTrigger
          id="normals-trigger"
          aria-expanded={isOpen}
          className="p-0"
          data-testid="normals-accordion-trigger"
          headerHeight="h-8"
          isOpen={isOpen.toString()}
        >
          <Typography as="div" className="text-sm font-normal">
            {FCT.FINALIZATION_MODAL.NORMALS.STATEMENTS}
          </Typography>
        </AccordionTrigger>
        <AccordionContent
          aria-labelledby="normals-accordion-content"
          border={false}
          data-testid="normals-accordion-content"
          role="group"
        >
          <div className="grid items-start gap-2">
            {normals.map((statement) => (
              <div
                key={statement}
                className="flex items-center gap-2 pl-1"
                data-testid="normal-statement"
              >
                <Icon className="size-3 fill-neutral-400" source={Checkmark} />
                <Typography
                  as="h5"
                  className="text-sm text-neutral-400 dark:text-neutral-400"
                  variant="paragraph-md"
                >
                  {statement}
                </Typography>
              </div>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

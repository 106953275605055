/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, cn, Icon, IconButton, IconSource, Tooltip } from '@prenuvo/halo-foundation';
import { ArrowRight, ChevronDown, CloseSidebar, OpenSidebar } from '@prenuvo/halo-icon';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

import { NavListProps, Progress } from '@/shared/model';
import { Skeleton } from '@/shared/ui';
import { OrganValidationStatus, useOrganValidationStore } from '@/store';

import { ProgressIndicator } from './progress-indicator';

export type NavMenuProps = {
  currentOrgan?: string;
  isCollapsed: boolean;
  isLoading?: boolean;
  isReviewVisible?: boolean;
  links: NavListProps[];
  maxSize: string;
  minSize: string;
  onLinkSelect?: (link: string) => void;
  onReviewClick?: () => void;
  onToggleSideMenu: () => void;
  selectedLinkValue?: string;
};

export function NavMenu({
  currentOrgan,
  isCollapsed,
  isLoading,
  isReviewVisible = true,
  links,
  maxSize,
  minSize,
  onLinkSelect,
  onReviewClick,
  onToggleSideMenu,
  selectedLinkValue,
}: NavMenuProps) {
  const containerControls = useAnimationControls();
  const [selectedOrgan, setSelectedOrgan] = useState('');
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);
  const linkItemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { getStatus } = useOrganValidationStore();
  const [hoveredMenu, setHoveredMenu] = useState<null | string>(null);
  const [isHoveringSubmenu, setIsHoveringSubmenu] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout>();

  const containerVariants = {
    close: {
      transition: {
        damping: 15,
        duration: 0.2,
        type: 'spring',
      },
      width: `${minSize}px`,
    },
    open: {
      transition: {
        damping: 20,
        duration: 0.4,
        type: 'spring',
      },
      width: `${maxSize}px`,
    },
  };

  useEffect(() => {
    if (selectedLinkValue) {
      setSelectedOrgan(selectedLinkValue);
    }
  }, [selectedLinkValue]);

  useEffect(() => {
    if (isCollapsed) {
      containerControls.start('close');
    } else {
      containerControls.start('open');
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (selectedOrgan) {
      const linkItem = linkItemRefs.current.find(
        (item) => item?.getAttribute('data-selected') === 'true',
      );

      if (linkItem) {
        linkItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [selectedOrgan]);

  const getStatusContent = (status: OrganValidationStatus | undefined) => {
    const result = ' - ';

    if (status?.error) {
      return `${result} Incomplete`;
    }

    if (status?.warning) {
      return `${result} ONCO/CSD 4+`;
    }

    return '';
  };

  const toggleSubmenu = (key: string) => {
    setExpandedMenus((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key],
    );
  };

  const renderNavLink = (link: NavListProps, index: number, isSubmenuItem = false) => {
    const isActive =
      link.key === selectedOrgan || link.sub?.some((sub) => sub.key === selectedOrgan);
    const isDisabled = false;
    const hasSubmenu = link.sub && link.sub.length > 0;
    const isExpanded = expandedMenus.includes(link.key);

    const hasSubmenuStatus = () =>
      link.sub?.some((subLink) => getStatus(subLink.key)?.error || getStatus(subLink.key)?.warning);

    const hasSubmenuError = () => link.sub?.some((subLink) => getStatus(subLink.key)?.error);

    const shouldShowIndicator = (linkKey: string) => {
      const status = getStatus(linkKey);
      const isCurrentPage = currentOrgan && linkKey === currentOrgan;

      if (status?.warning || (hasSubmenu && hasSubmenuStatus())) {
        return true;
      }

      return !isCurrentPage && (status?.error || (hasSubmenu && hasSubmenuError()));
    };

    const getIndicatorProgress = (linkKey: string) => {
      const status = getStatus(linkKey);
      const isCurrentPage = currentOrgan && linkKey === currentOrgan;

      if (
        (!isCurrentPage && !status?.error && status?.warning) ||
        (isCurrentPage && status?.warning)
      ) {
        return Progress.ONCORAD;
      }

      if (!isCurrentPage && (status?.error || (hasSubmenu && hasSubmenuError()))) {
        return Progress.MISSING_INPUT;
      }

      return Progress.ONCORAD;
    };

    return (
      <div
        key={link.key}
        className="relative"
        onMouseEnter={() => {
          clearTimeout(hoverTimeoutRef.current);
          setHoveredMenu(link.key);
        }}
        onMouseLeave={() => {
          hoverTimeoutRef.current = setTimeout(() => {
            if (!isHoveringSubmenu) {
              setHoveredMenu(null);
            }
          }, 500);
        }}
      >
        <Link
          className={cn(
            'group px-2 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium text-neutral-400 dark:text-neutral-400 hover:no-underline w-full',
            {
              'border-b border-dashed border-stone-700 py-2': link.key === 'reason_for_scan',
              'dark:bg-stone-800': isActive && isSubmenuItem,
              'pl-8 pr-2 hover:bg-stone-800 rounded-lg': isSubmenuItem,
              'text-neutral-800 dark:text-neutral-800': isDisabled || link.disabled,
            },
          )}
          data-testid="nav-menu-link"
          onClick={(e) => {
            if (hasSubmenu) {
              e.preventDefault();
              toggleSubmenu(link.key);
            } else {
              setSelectedOrgan(link.key);

              if (onLinkSelect) {
                onLinkSelect(link.key);
              }
            }
          }}
          to="#"
        >
          <Tooltip
            className={cn(
              'bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50',
              {
                hidden:
                  (!isCollapsed &&
                    getStatusContent(getStatus(link.key)) === '' &&
                    !link.disabled) ||
                  hasSubmenu,
              },
            )}
            content={`${link.title} ${link.disabled ? '- Not in SKU' : getStatusContent(getStatus(link.key))}`}
            side="right"
          >
            <div
              className={cn(
                'flex w-full justify-between static px-2 py-3 hover:bg-stone-800 dark:hover:bg-stone-800 rounded-lg h-10 items-center hover:text-neutral-400 dark:hover:text-neutral-400',
                {
                  'bg-stone-800 dark:bg-stone-800 text-neutral-50 dark:text-neutral-50 hover:text-neutral-50 dark:hover:text-neutral-50':
                    isActive && (!hasSubmenu || !isExpanded || (isExpanded && isCollapsed)),
                  'text-neutral-50 dark:text-neutral-50 hover:text-neutral-50 dark:hover:text-neutral-50':
                    isActive && hasSubmenu && isExpanded,
                  'text-neutral-800 dark:text-neutral-800':
                    isSubmenuItem && link.disabled && !isActive,
                  'w-auto relative px-3': isCollapsed,
                },
              )}
              data-selected={`${isActive}`}
              ref={(el: HTMLDivElement | null) => {
                linkItemRefs.current[index] = el;
              }}
            >
              {isCollapsed && link.icon && (
                <div>
                  <Icon
                    aria-label={link.title}
                    className="fill-current"
                    size="md"
                    source={link.icon as IconSource}
                  />
                </div>
              )}
              {!isCollapsed && (
                <span className="flex items-center">
                  {link.icon && (
                    <Icon
                      aria-label={link.title}
                      className="mr-[12px] fill-current"
                      size="md"
                      source={link.icon as IconSource}
                    />
                  )}
                  <span className="whitespace-normal text-sm font-normal no-underline">
                    {link.title}
                  </span>
                </span>
              )}
              <div className="flex items-center">
                {(!hasSubmenu || (hasSubmenu && !isExpanded) || (hasSubmenu && isCollapsed)) &&
                  shouldShowIndicator(link.key) && (
                    <span className="ml-auto flex items-center">
                      <ProgressIndicator
                        isCollapsed={isCollapsed}
                        progress={getIndicatorProgress(link.key)}
                      />
                    </span>
                  )}
                {hasSubmenu && !isCollapsed && (
                  <Icon
                    className={cn(
                      'h-4 w-4 transition-transform fill-current ml-2',
                      isExpanded && 'rotate-180',
                    )}
                    source={ChevronDown}
                  />
                )}
              </div>
            </div>
          </Tooltip>
        </Link>
        {hasSubmenu && isExpanded && !isCollapsed && (
          <div className="ml-2">
            {link.sub?.map((subLink, subIndex) =>
              renderNavLink(
                {
                  key: subLink.key,
                  disabled: subLink.disabled,
                  progress: null,
                  title: subLink.value,
                },
                index + subIndex,
                true,
              ),
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSubmenuPopover = (link: NavListProps) => {
    if (!link.sub?.length || link.key !== hoveredMenu) {
      return null;
    }

    const item = linkItemRefs.current[links.indexOf(link)];
    const topOffset = item?.getBoundingClientRect().top || 0;
    const parentRect = item?.closest('[data-testid="nav-menu"]')?.getBoundingClientRect();
    const relativeTop = parentRect ? topOffset - parentRect.top : 0;

    const shouldShowSubmenuIndicator = (subLinkKey: string) => {
      const status = getStatus(subLinkKey);
      const isCurrentPage = currentOrgan && subLinkKey === currentOrgan;

      if (status?.warning) {
        return true;
      }

      return !isCurrentPage && status?.error;
    };

    const getSubmenuIndicatorProgress = (subLinkKey: string) => {
      const status = getStatus(subLinkKey);
      const isCurrentPage = currentOrgan && subLinkKey === currentOrgan;

      if (
        (!isCurrentPage && !status?.error && status?.warning) ||
        (isCurrentPage && status?.warning)
      ) {
        return Progress.ONCORAD;
      }

      if (!isCurrentPage && status?.error) {
        return Progress.MISSING_INPUT;
      }

      return Progress.ONCORAD;
    };

    return (
      <div
        key={`submenu-${link.key}`}
        className="absolute left-full top-0 z-50 ml-2 min-w-48 rounded-lg bg-stone-900 shadow-lg"
        onMouseEnter={() => {
          clearTimeout(hoverTimeoutRef.current);
          setIsHoveringSubmenu(true);
        }}
        onMouseLeave={() => {
          setIsHoveringSubmenu(false);
          setHoveredMenu(null);
        }}
        style={{ top: `${relativeTop}px` }}
      >
        {link.sub.map((subLink) => (
          <Link
            key={`submenu-${subLink.key}`}
            className="text-md group inline-flex w-full items-center whitespace-nowrap text-neutral-400 hover:no-underline dark:text-neutral-400"
            data-testid="nav-menu-link"
            onClick={() => {
              setSelectedOrgan(subLink.key);
              onLinkSelect?.(subLink.key);
            }}
            to="#"
          >
            <Tooltip
              className={cn(
                'bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50',
                {
                  hidden: isCollapsed && getStatusContent(getStatus(subLink.key)) === '',
                },
              )}
              content={`${subLink.value} ${getStatusContent(getStatus(subLink.key))}`}
              side="right"
            >
              <div
                className={cn(
                  'flex w-full static p-2 hover:bg-stone-800 dark:hover:bg-stone-800 rounded-lg h-10 items-center hover:text-neutral-400 dark:hover:text-neutral-400',
                  {
                    'bg-stone-800 dark:bg-stone-800 text-neutral-50 dark:text-neutral-50 hover:text-neutral-50 dark:hover:text-neutral-50':
                      subLink.key === selectedOrgan,
                  },
                )}
                data-selected={`${subLink.key === selectedOrgan}`}
              >
                {subLink.value}
                {shouldShowSubmenuIndicator(subLink.key) && (
                  <span className="ml-auto flex items-center">
                    <ProgressIndicator
                      isCollapsed={false}
                      progress={getSubmenuIndicatorProgress(subLink.key)}
                    />
                  </span>
                )}
              </div>
            </Tooltip>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        animate={containerControls}
        className="relative"
        data-collapsed={isCollapsed}
        data-testid="nav-menu"
        variants={containerVariants}
      >
        <nav
          className={cn(
            'rounded-2xl bg-stone-900 dark:bg-stone-900 pr-1 pt-1 overflow-hidden h-full flex flex-col justify-between',
            isCollapsed && `justify-items-center`,
          )}
        >
          {isLoading || links.length === 0 ? (
            <div className="mt-2 flex flex-col gap-4 overflow-y-auto px-2">
              <Skeleton className="h-8" count={24} />
            </div>
          ) : (
            <div className="custom-scrollbar grid w-full grid-flow-row auto-rows-max grid-cols-none overflow-y-auto overflow-x-hidden pb-3">
              {links.map((link, index) => renderNavLink(link, index))}
            </div>
          )}
          <div>
            {isReviewVisible && (
              <div className="flex w-full place-content-center border-t border-dashed border-stone-700 px-3 py-2 dark:border-stone-700">
                <Tooltip
                  className={cn(
                    'bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50',
                    {
                      hidden: !isCollapsed,
                    },
                  )}
                  content="Review and Sign"
                  side="bottom"
                >
                  <Button
                    aria-label="Review and Sign"
                    className={{ root: 'flex w-full items-center justify-center' }}
                    data-testid="review-sign-button"
                    onClick={onReviewClick}
                    rightIcon={isCollapsed ? { source: ArrowRight } : undefined}
                    size="sm"
                    variant="primary"
                  >
                    {!isCollapsed && <span className="px-6">Review & Sign</span>}
                  </Button>
                </Tooltip>
              </div>
            )}
            <div
              className={cn(
                isCollapsed && 'w-full place-content-center',
                'py-1 px-4 border-t border-dashed border-stone-700 dark:border-stone-700',
                !isCollapsed && 'flex justify-center',
              )}
            >
              <Tooltip
                className="bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50"
                content={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                side="bottom"
              >
                <IconButton
                  aria-label="Toggle sidebar"
                  className={{
                    icon: 'fill-neutral-400 hover:fill-neutral-50',
                  }}
                  data-testid="menu-toggle-button"
                  icon={isCollapsed ? OpenSidebar : CloseSidebar}
                  onClick={onToggleSideMenu}
                  size="md"
                  variant="text"
                />
              </Tooltip>
            </div>
          </div>
        </nav>

        {isCollapsed && hoveredMenu && links.map(renderSubmenuPopover)}
      </motion.div>
    </AnimatePresence>
  );
}

import { IconSource } from '@prenuvo/halo-foundation';
import { QuestionCircle } from '@prenuvo/halo-icon';

import { FormStructure, NormalizeCheckItem, ReportOrgan } from '@/api';

export const getIconByFormStructure = (title: string, formStructure: FormStructure) => {
  const organ = formStructure.structure.find(
    (organData) => 
      (organData.sub?.some(subOrgan => subOrgan.key === title)) ||
      organData.key === title
  );

  return (organ?.icon ?? QuestionCircle) as IconSource;
};

export const getNormalStatementsWithDefaultTrue = (reportOrgan: ReportOrgan) => {
  if (!reportOrgan?.normaliseCheck) {
    return [];
  }

  return Object.entries(reportOrgan.normaliseCheck)
    .filter(
      ([key, statement]) =>
        key.startsWith('norm_') && (statement as NormalizeCheckItem).defaultBool === true,
    )
    .map(([, statement]) => (statement as NormalizeCheckItem).value);
};

export const parseOrganTitle = (organ: string) => {
  const organName = organ.split('_').join(' ');

  return organName.charAt(0).toUpperCase() + organName.slice(1);
};

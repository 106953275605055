export enum IssueType {
  REPORT_ISSUE = 'Report issue or bug',
  SUGGEST_IMPROVEMENT = 'Suggest improvement',
}

export enum AboutType {
  PATIENT_HISTORY = 'Patient history, priors, and reason for scan',
  ORGANS = 'Organs, findings, and outputs',
  KEY_IMAGES = 'Key images (KI)',
  FINAL_IMPRESSIONS = 'Final impressions',
  REPORT_PREVIEW = 'Report preview and signature',
  SECURITY = 'Security and privacy',
  GENERAL_SYSTEM_USABILITY = 'General system usability',
  OTHER = 'Other',
}

export type CommonStyles = {
  input: string;
  select: {
    content: string;
    trigger: string;
  };
};

export type IssueTypeOption = {
  value: IssueType;
};

export type AboutTypeOption = {
  value: AboutType;
};

export type DescriptionGuide = {
  items: string[];
  title: string;
};

export type DescriptionGuides = {
  [key: string]: DescriptionGuide;
};

export type ReportIssueProps = {
  buttonSize?: 'md' | 'sm';
};

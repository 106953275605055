import {
  ConditionDetailData,
  ConditionDetailOperator,
  ConditionDetailOperatorValue,
  Finding,
  RenderSummaryPayload,
  Report,
  ReportFindingPayload,
} from '@/api';

import { DEFAULT_QUESTIONS } from '../constant';
import { ComparisonOperator, MissingField } from '../model';

export const replaceConditionWithValue = (inputText: string): string => {
  const regex = /<{name: "[^"]+", value: (null|"[^"]+")(?:, uuid: "[^"]+")?\}>/g;

  return inputText.replace(regex, (_, p1) =>
    // If p1 is null, replace with an empty string
    p1 === 'null' ? '' : p1.replace(/^"|"$/g, ''),
  );
};

export const reportFindingPayload = (
  uuid: string,
  conditionDetail: ConditionDetailData[],
  keyImageUuids: string[],
  observation?: string,
): ReportFindingPayload => ({
  conditionDetail,
  keyImageUuids,
  observation,
  status: 'draft',
  templateUuid: uuid,
});

export const renderSummaryPayload = (
  uuid: string,
  report: Report,
  reportFindingsUuids: string[] = [],
): RenderSummaryPayload => ({
  actionDetails: '',
  actionSummary: '',
  findingDetails: '',
  findingSummary: '',
  reportFindings: [],
  reportFindingsUuids: uuid ? [...reportFindingsUuids, uuid] : [...reportFindingsUuids],
  reportUuid: report!.uuid,
  status: 'draft',
});

export const evaluateRequiredWhen = (
  requiredWhen: ConditionDetailOperator,
  findings: Finding[],
) => {
  if (!requiredWhen && !findings) {
    return false;
  }

  const { condition, operator } = requiredWhen;
  const { field, value } = condition as ConditionDetailOperatorValue;

  if (operator === 'not') {
    if (field === 'condition') {
      return !findings?.find((finding) => finding.conditionTemplate.condition === value);
    }

    const matchingFinding = findings?.filter((finding) => {
      const { conditionDetail } = finding.conditionTemplate;

      return conditionDetail?.some((detail) => detail.name === field);
    });

    if (matchingFinding?.length) {
      const matchingFindingValue = matchingFinding
        ?.map((finding) => finding.renderedSummary.reportFindings[0])
        .filter(Boolean)
        .flatMap((reportFinding) => reportFinding.conditionDetail)
        .find((detail) => detail?.name === field)?.value;

      return matchingFindingValue !== value;
    }

    return true;
  }

  return false;
};

export const evaluateConditionShowWhen = (
  showWhen: ConditionDetailOperator,
  allValues: { [key: string]: unknown },
  organFindings: Finding[] = [],
): boolean => {
  if (!showWhen) {
    return true;
  }

  const { condition, operator } = showWhen;

  if (operator === 'never' && !condition) {
    return false;
  }

  if (operator === 'or' || operator === 'and') {
    if (!Array.isArray(condition)) {
      return true;
    }

    if (operator === 'or') {
      return condition.some((cond) =>
        evaluateConditionShowWhen({ condition: cond, operator: '===' }, allValues, organFindings),
      );
    }

    return condition.every((cond) =>
      evaluateConditionShowWhen({ condition: cond, operator: '===' }, allValues, organFindings),
    );
  }

  if (operator === 'not' && Array.isArray(condition)) {
    const { field } = condition[0];
    let fieldValue = allValues[field];

    if (fieldValue === undefined) {
      fieldValue = organFindings
        .map((finding) => finding.renderedSummary.reportFindings[0])
        .filter(Boolean)
        .flatMap((reportFinding) => reportFinding.conditionDetail)
        .find((detail) => detail?.name === field)?.value;
    }

    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return false;
    }

    return condition.every(
      (cond) =>
        !evaluateConditionShowWhen({ condition: cond, operator: '===' }, allValues, organFindings),
    );
  }

  const { field, value } = condition as ConditionDetailOperatorValue;

  let fieldValue = allValues[field];

  if (fieldValue === undefined) {
    fieldValue = organFindings
      .map((finding) => finding.renderedSummary.reportFindings[0])
      .filter(Boolean)
      .flatMap((reportFinding) => reportFinding.conditionDetail)
      .find((detail) => detail?.name === field)?.value;
  }

  switch (operator as ComparisonOperator) {
    case '===':
      return fieldValue === value;
    case 'any':
      return Array.isArray(value) ? value.includes(fieldValue as string) : false;
    case '>':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue > value;
    case '>=':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue >= value;
    case '<':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue < value;
    case '<=':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue <= value;
    case 'not':
      return fieldValue !== value;
    default:
      return true;
  }
};

export const findMissingRequiredDetails = (
  groupedData: {
    [key: string]: Finding[];
  },
  isReviewClicked: boolean = false,
): MissingField => {
  const result: MissingField = {};

  Object.keys(groupedData).forEach((key) => {
    const organData = groupedData[key] || [];

    if (DEFAULT_QUESTIONS?.[key] && isReviewClicked) {
      Object.entries(DEFAULT_QUESTIONS?.[key]).forEach(([question, questionData]) => {
        const organFindings = organData || [];
        const requiredWhen = questionData?.requiredWhen;
        let requiredQuestions;

        if (requiredWhen) {
          requiredQuestions = evaluateRequiredWhen(requiredWhen, organFindings);
        } else {
          requiredQuestions = questionData?.required;
        }

        if (requiredQuestions) {
          const hasMissingRequired = !organFindings.some(
            (finding) => finding.conditionTemplate.condition === question,
          );

          if (hasMissingRequired) {
            result[key] = { error: true, findingIndex: questionData.order, observationIndex: 0 };
          }
        }
      });
    }

    organData.forEach((item, findingIndex) => {
      const { conditionDetail = [] } = item.conditionTemplate;
      const { reportFindings } = item.renderedSummary;

      reportFindings.forEach((reportFinding, observationIndex) => {
        const { conditionDetail: conditionDetailData } = reportFinding;

        const requiredFields = conditionDetail.filter((detail) => {
          if (detail.requiredWhen) {
            return evaluateRequiredWhen(detail.requiredWhen, organData);
          }

          if (!detail.required) {
            return false;
          }

          if (!detail.showWhen) {
            return true;
          }

          return evaluateConditionShowWhen(
            detail.showWhen,
            conditionDetailData.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.name]: curr.value,
              }),
              {},
            ),
            organData,
          );
        });

        if (requiredFields.length === 0) {
          return;
        }

        const isRequiredFieldsPresent = requiredFields.every((field) =>
          conditionDetailData.some(
            (detail) => detail.name === field.name && detail.value !== null && detail.value !== '',
          ),
        );

        if (!isRequiredFieldsPresent) {
          result[key] = { error: !isRequiredFieldsPresent, findingIndex, observationIndex };
        }
      });
    });
  });

  return result;
};

import { cn } from '@prenuvo/halo-foundation';

export interface SkeletonProps {
  className?: string;
  /**
   * Number of skeleton items to render
   * @default 1
   */
  count?: number;
  testId?: string;
}

export function Skeleton({ className, count = 1, testId }: SkeletonProps) {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`skeleton-${index}`}
          className={cn('animate-pulse rounded-md bg-neutral-700/30', className)}
          data-testid={testId}
        />
      ))}
    </>
  );
}

import { createContext } from 'react';

import { Theme } from './theme.model';

type ThemeState = {
  setTheme: (theme: Theme) => void;
  theme: Theme;
};

export const ThemeContext = createContext<ThemeState>({
  setTheme: () => null,
  theme: 'system',
});

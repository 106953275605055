export const convertTextToBulletPoints = (text: string): string => {
  if (!text) {
    return '';
  }

  if (text.includes('\n')) {
    const lines = text.split('\n').filter((line) => line.trim() !== '');

    if (lines.length === 0) {
      return '';
    }

    let result = '';
    let currentLevel = '';
    let hasLevels = false;

    lines.forEach((line) => {
      if (line.match(/([CTLS])(\d+)\/([CTLS])(\d+)/) || line.match(/Overall\s+\w+:/)) {
        if (currentLevel) {
          result += '</ol>';
        }

        currentLevel = line.trim();
        result += `<p>${currentLevel}</p><ol>`;
        hasLevels = true;
      } else {
        result += `<li data-list="bullet"><span class="ql-ui" contenteditable="false"></span>${line.trim()}</li>`;
      }
    });

    if (currentLevel) {
      result += '</ol>';
    }

    if (!hasLevels) {
      result = `<ol>${result}</ol>`;
    }

    return result;
  }

  return text;
};

import { TEMP_QA_ENABLED } from '@/api/mocks/constants';

export function determineSku(skuID: string | undefined): string {
  if (!skuID) {
    return TEMP_QA_ENABLED ? '300' : '';
  }

  if (skuID === '360') {
    return '350';
  }

  return skuID;
}

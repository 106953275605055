import { DefaultQuestions } from '../model';

export const DEFAULT_QUESTIONS: DefaultQuestions = {
  breasts: {
    default_question_amount_of_fibroglandular_tissue: {
      order: 2,
      questions: {
        tissue_density: {
          label: 'Fibroglandular Tissue (FGT)',
        },
      },
      required: true,
    },
    default_question_mastectomy: {
      order: 1,
      questions: {
        laterality: {
          label: 'Mastectomy Laterality',
        },
      },
      required: true,
    },
  },
  gallbladder_and_biliary_system: {
    default_question_gallbladder_appearance: {
      order: 1,
      required: true,
    },
  },
  ovaries: {
    default_question_ovary_appearance: {
      order: 1,
      required: true,
    },
  },
  prostate: {
    default_question_total_size_of_prostate: {
      order: 1,
      required: false,
      requiredWhen: {
        condition: {
          field: 'condition',
          value: 'prostatectomy',
        },
        operator: 'not',
      },
    },
  },
  spine: {
    default_question_conus_level: {
      order: 2,
    },
    default_question_vertebrae_numbering: {
      header: 'Spine count',
      order: 1,
      questions: {
        number_of_c_spine_vertebrae: {
          default_value: '7',
          label: '# of C-spine vertebrae',
        },
        number_of_l_spine_vertebrae: {
          default_value: '5',
          label: '# of L-spine vertebrae',
        },
        number_of_t_spine_vertebrae: {
          default_value: '12',
          label: '# of T-spine vertebrae',
        },
      },
      required: true,
    },
  },
  stomach: {
    default_question_fasted: {
      order: 1,
      questions: {
        patient_fasted: {
          label: 'Has the patient fasted appropriately (ie. No evidence of peristalsis)',
        },
      },
    },
  },
  uterus: {
    default_question_c_section_scar: {
      order: 2,
    },
    default_question_endometrial_thickness: {
      order: 5,
    },
    default_question_intrauterine_device: {
      order: 3,
    },
    'default_question_post_-_menopausal_status': {
      order: 4,
    },
    default_question_uterus_appearance: {
      order: 1,
    },
  },
};

export const HIDDEN_QUESTION_ORGANS = ['stomach'];

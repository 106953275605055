import { ReactNode, useMemo } from 'react';

import { useAuthStore } from '@/store';

import { AuthContext } from './auth.context';

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const { isAuthenticated } = useAuthStore();

  const value = useMemo(() => ({ isAuthenticated }), [isAuthenticated]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

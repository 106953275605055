/* eslint-disable perfectionist/sort-objects */
import { AboutType } from './report-issue.model';

export const getShortAboutType = (type: AboutType): string => {
  const mapping: Record<AboutType, string> = {
    [AboutType.PATIENT_HISTORY]: '[Hx, P, Reason]',
    [AboutType.ORGANS]: '[Organ]',
    [AboutType.KEY_IMAGES]: '[KI]',
    [AboutType.FINAL_IMPRESSIONS]: '[Final imp]',
    [AboutType.REPORT_PREVIEW]: '[Report prev]',
    [AboutType.SECURITY]: '[Security]',
    [AboutType.GENERAL_SYSTEM_USABILITY]: '[Usability]',
    [AboutType.OTHER]: '[Other]',
  };

  return mapping[type] || type;
};

import { Input, Select } from '@prenuvo/halo-foundation';

import { FCT } from '@/i18n';
import { Textarea } from '@/shared/ui';

import { useReportIssueContext } from '../report-context';
import { ABOUT_TYPES, COMMON_STYLES, ISSUE_TYPES } from '../report-issue.constant';
import { AboutType, IssueType } from '../report-issue.model';
import { ReportCommonLabel } from './report-common-label.component';

export function ReportFormFields() {
  const {
    aboutType,
    description,
    issueType,
    setAboutType,
    setDescription,
    setIssueType,
    setTitle,
    showErrors,
    title,
  } = useReportIssueContext();

  return (
    <>
      <div className="flex gap-4">
        <div className="flex-1">
          <ReportCommonLabel
            isRequired
            issueType={issueType}
            labelText={FCT.REPORTING.REPORT_ISSUE.LABEL.WHAT_WOULD_YOU_LIKE_TO_DO}
            showErrors={showErrors}
            title={title}
          />
          <Select
            aria-label={FCT.REPORTING.REPORT_ISSUE.LABEL.WHAT_WOULD_YOU_LIKE_TO_DO}
            className={COMMON_STYLES(showErrors && !issueType).select}
            data-testid="issue-type-select"
            name="issue-type"
            onValueChange={(value: IssueType) => setIssueType(value)}
            options={ISSUE_TYPES}
            value={issueType}
            variant="outlined"
          />
        </div>
        <div className="flex-1">
          <ReportCommonLabel
            issueType={issueType}
            labelText={FCT.REPORTING.REPORT_ISSUE.LABEL.WHAT_IS_IT_ABOUT}
            showErrors={showErrors}
            title={title}
          />
          <Select
            aria-label={FCT.REPORTING.REPORT_ISSUE.LABEL.WHAT_IS_IT_ABOUT}
            className={COMMON_STYLES().select}
            data-testid="about-type-select"
            name="about-type"
            onValueChange={(value: AboutType) => setAboutType(value)}
            options={ABOUT_TYPES}
            value={aboutType}
            variant="outlined"
          />
        </div>
      </div>
      <div>
        <ReportCommonLabel
          isRequired
          issueType={issueType}
          labelText={FCT.REPORTING.REPORT_ISSUE.LABEL.TITLE}
          showErrors={showErrors}
          title={title}
        />
        <Input
          className={COMMON_STYLES(showErrors && !title).input}
          data-testid="issue-title-input"
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          value={title}
        />
      </div>
      <div>
        <ReportCommonLabel
          issueType={issueType}
          labelText={FCT.REPORTING.REPORT_ISSUE.LABEL.DESCRIPTION}
          showErrors={showErrors}
          title={title}
        />
        <Textarea
          className={COMMON_STYLES().input}
          data-testid="issue-description-textarea"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          variant="outlined"
        />
      </div>
    </>
  );
}

export enum Progress {
  IN_PROGRESS = 'InProgress',
  ERROR = 'Error',
  COMPLETED = 'Completed',
  MISSING_INPUT = 'missingInput',
  ONCORAD = 'oncorad',
}

export type NavListSubProps = {
  disabled?: boolean;
  key: string;
  value: string;
};

export type NavListProps = {
  disabled?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
  key: string;
  progress: null | Progress;
  sub?: NavListSubProps[];
  title: string;
};

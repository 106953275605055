import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS, FormStructure, makeApiRequest } from '@/api';
import { TEMP_QA_ENABLED } from '@/api/mocks/constants';
import { processFormStructure } from '@/shared/util';
import { transformStructureToNavList } from '@/shared/util/transform.util';
import { FormStructureSummary, useFormStructureStore, usePatientStore } from '@/store';

import { determineSku } from './form-structure.util';

export const useFormStructure = (studyId: string | undefined, skuId: string | undefined) => {
  const { setFormStructure } = useFormStructureStore();
  const { patient } = usePatientStore();
  const [formStructureData, setFormStructureData] = useState<FormStructureSummary>();

  const has = {
    patient: patient !== null,
    sku: skuId !== undefined,
    studyId: studyId !== undefined,
  };

  const sku = determineSku(skuId);

  const fetchFormStructure = () =>
    makeApiRequest<FormStructure[]>(
      'FCT',
      `${API_ENDPOINTS.organs.getOrgans}?sku=${sku}&status=draft`,
      'GET',
    );

  const enabled = TEMP_QA_ENABLED ? has.studyId : has.studyId && has.sku && has.patient;

  const { data: formStructure, isLoading } = useQuery({
    enabled,
    queryFn: fetchFormStructure,
    queryKey: ['formStructure', studyId],
    refetchOnWindowFocus: false,
    retryOnMount: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    const shouldTransformData = TEMP_QA_ENABLED ? true : has.sku && has.patient;

    if (formStructure && shouldTransformData) {
      const processedFormStructure = processFormStructure(formStructure, patient?.gender || 'male');
      const transformedData = transformStructureToNavList(processedFormStructure);

      setFormStructure(transformedData);
      setFormStructureData(transformedData);
    }
  }, [formStructure, patient, skuId]);

  return { formStructure: formStructureData, isLoading };
};

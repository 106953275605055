import { BASE_API_URL } from '@/core/config';

export const API_ENDPOINTS = {
  conditionTemplate: {
    getConditionTemplate: `${BASE_API_URL}/condition-template/`,
  },
  dicom: {
    deleteSeries: `${BASE_API_URL}/dicom/deleteSeries`,
    seriesRetrieve: `${BASE_API_URL}/dicom/series-retrieve`,
  },
  finding: {
    getfinding: `${BASE_API_URL}/text-to-structured-observation/`,
    renderedSummary: `${BASE_API_URL}/rendered-summary/`,
    reportFinding: `${BASE_API_URL}/report-finding/`,
  },
  hi: {
    radStudy: '/radiologist/study/',
    study: `${BASE_API_URL}/study`,
  },
  keyImages: {
    getKeyImagesByRenderedSummary: `${BASE_API_URL}/key-image/rendered-summary/`,
    keyImage: `${BASE_API_URL}/key-image/`,
  },
  organs: {
    getOrgans: `${BASE_API_URL}/form-structure/`,
  },
  report: {
    getReport: `${BASE_API_URL}/report/study`,
    saveReport: `${BASE_API_URL}/report`,
  },
  reportOrgan: {
    getReportOrganByUuid: `${BASE_API_URL}/report-organ`,
    updateReportOrgan: `${BASE_API_URL}/report-organ/from-updated-findings`,
  },
  reportPdf: {
    createReportPdf: `${BASE_API_URL}/report-pdf/`,
  },
  study: {
    getStudy: `${BASE_API_URL}/study/`,
  },
  user: {
    getCurrentUser: `${BASE_API_URL}/userV2/current`,
    getPatient: `${BASE_API_URL}/userV2/`,
  },
};

export const ENDPOINTS = {
  APPS: {
    STUDY: `${BASE_API_URL}/study/`,
  },
  FCT: {
    APPS: {
      REPORT_ISSUE: `${BASE_API_URL}/apps/report-issue`,
    },
    CONDITION_TEMPLATE: `${BASE_API_URL}/condition-template/`,
    FORM_STRUCTURE: `${BASE_API_URL}/form-structure/`,
    KEY_IMAGE: {
      BASE: `${BASE_API_URL}/key-image/`,
      RENDERED_SUMMARY: `${BASE_API_URL}/key-image/rendered-summary/`,
    },
    OBSERVATION: `${BASE_API_URL}/text-to-structured-observation/`,
    RENDERED_SUMMARY: `${BASE_API_URL}/rendered-summary/`,
    REPORT: {
      BASE: `${BASE_API_URL}/report/`,
      STUDY: `${BASE_API_URL}/report/study/`,
    },
    REPORT_FINDING: `${BASE_API_URL}/report-finding/`,
    REPORT_ORGAN: {
      FROM_UPDATED_FINDINGS: `${BASE_API_URL}/report-organ/from-updated-findings/`,
      REPORT: `${BASE_API_URL}/report-organ/report/`,
    },
    REPORT_PDF: `${BASE_API_URL}/report-pdf/`,
  },
  HI: {
    DICOM: {
      DELETE_SERIES: `${BASE_API_URL}/dicom/deleteSeries`,
      SERIES_RETRIEVE: `${BASE_API_URL}/dicom/series-retrieve`,
    },
    RADIOLOGIST: {
      STUDY: '/radiologist/study/',
    },
    STUDY: `${BASE_API_URL}/study/`,
  },
  PII: {
    PATIENT: `${BASE_API_URL}/userV2/`,
    USER: `${BASE_API_URL}/userV2/current`,
  },
} as const;

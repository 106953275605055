import { Navigate } from 'react-router-dom';

import { AuthGuard } from '@/core/auth';
import { NoAccessRoute } from '@/modules/no-access';
import { NotFoundRoute } from '@/modules/not-found';
import {
  ReportingEntryFinalImpressionRoute,
  ReportingEntryKeyImagesRetrieveRoute,
  ReportingEntryKeyImagesRoute,
  ReportingEntryOrganRoute,
  ReportingEntryReasonForScanRoute,
  ReportingPreviewRoute,
} from '@/modules/reporting';
import { MainLayout } from '@/shared/layout';
import { DefaultStudyWrapper } from '@/shared/ui';

export const ROUTES = [
  {
    children: [
      {
        element: <Navigate replace to="entry/reason-for-scan" />,
        index: true,
      },
      {
        element: <ReportingEntryReasonForScanRoute />,
        path: 'entry/reason-for-scan',
      },
      {
        element: <ReportingEntryOrganRoute />,
        path: 'entry/organ/:organ',
      },
      {
        element: <ReportingEntryFinalImpressionRoute />,
        path: 'entry/final-impressions',
      },
      {
        element: <ReportingEntryKeyImagesRoute />,
        path: 'entry/key-images',
      },
      {
        element: <ReportingEntryKeyImagesRetrieveRoute />,
        path: 'entry/retrieve-key-images',
      },
    ],
    element: (
      <AuthGuard>
        <DefaultStudyWrapper>
          <MainLayout />
        </DefaultStudyWrapper>
      </AuthGuard>
    ),
    path: '/reporting/:id',
  },
  {
    element: (
      <AuthGuard>
        <ReportingPreviewRoute />
      </AuthGuard>
    ),
    path: '/reporting/:id/preview',
  },
  {
    element: <Navigate replace to="/404" />,
    path: '*',
  },
  {
    element: <NotFoundRoute />,
    path: '404',
  },
  {
    element: <NoAccessRoute />,
    path: '401',
  },
];

export const ORGANS_HISTORY_MAP = {
  Adrenals: 'adrenals',
  Ankles: 'ankles',
  Bladder: 'bladder_and_ureters',
  BonySkeleton: 'bony_skeleton',
  BowelAndAbdomenPelvicLymphNodes: 'bowel',
  Brain: 'brain',
  Breasts: 'breasts',
  Esophagus: 'esophagus',
  GallbladderAndBiliarySystem: 'gallbladder_and_biliary_system',
  HeartAndGreatVessels: 'heart_and_great_vessels',
  Kidneys: 'kidneys',
  Knees: 'knees',
  Liver: 'liver',
  LungsAndMediastinum: 'lungs',
  Ovaries: 'ovaries',
  Pancreas: 'pancreas',
  PelvisAndHips: 'hips',
  Prostate: 'prostate',
  Shoulders: 'shoulder',
  SinusesAndMastoids: 'sinuses',
  SoftTissueNeck: 'neck',
  Spine: 'spine',
  Stomach: 'stomach',
  Uterus: 'uterus',
} as const;

export type OrganHistoryKey = keyof typeof ORGANS_HISTORY_MAP;

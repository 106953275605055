import { RouterProvider } from 'react-router-dom';
import { Toaster, TooltipProvider } from '@prenuvo/halo-foundation';
import { QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from '@/core/auth';
import { dataDogLogger } from '@/core/data-dog';
import { queryClient } from '@/core/react-query';
import { ThemeProvider } from '@/core/theme';
import { UserState } from '@/core/user';
import { ROUTER } from '@/routes';

export function App() {
  dataDogLogger.debug('Starting application');

  return (
    <AuthProvider>
      <ThemeProvider defaultTheme="dark">
        <Toaster>
          <TooltipProvider>
            <QueryClientProvider client={queryClient}>
              <UserState>
                <RouterProvider router={ROUTER} />
              </UserState>
            </QueryClientProvider>
          </TooltipProvider>
        </Toaster>
      </ThemeProvider>
    </AuthProvider>
  );
}

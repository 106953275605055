import { Button, cn } from '@prenuvo/halo-foundation';

import { FCT } from '@/i18n';

import { useReportIssueContext } from '../report-context';

function ReportFormActions() {
  const { handleSubmit, isReportIssueSuccess, resetForm, resetReportIssue, setShowReportIssue } =
    useReportIssueContext();

  return (
    <div className={cn('flex justify-end gap-4', { 'justify-center': isReportIssueSuccess })}>
      {/* <div className="flex items-center gap-2">
        <input
          id="file-upload"
          accept=".jpg,.jpeg,.png,.gif"
          className="hidden"
          data-testid="file-upload-input"
          multiple
          onChange={handleFileChange}
          type="file"
        />
        <Button
          data-testid="attach-file-button"
          onClick={() => document.getElementById('file-upload')?.click()}
          variant="outlined"
        >
          Attach file
        </Button>
        <Typography variant="paragraph-sm">
          Maximum of five JPEG, PNG, GIF files up to 5MB each.
        </Typography>
      </div> */}

      {isReportIssueSuccess ? (
        <Button
          className={{ root: 'px-8' }}
          data-testid="close-button"
          onClick={() => {
            setShowReportIssue(false);
            resetReportIssue();
          }}
          variant="primary"
        >
          {FCT.REPORTING.REPORT_ISSUE.ACTIONS.CLOSE}
        </Button>
      ) : (
        <div className="flex gap-4">
          <Button
            className={{ root: 'px-8' }}
            data-testid="cancel-button"
            onClick={() => {
              setShowReportIssue(false);
              resetForm();
            }}
            variant="outlined"
          >
            {FCT.REPORTING.REPORT_ISSUE.ACTIONS.CANCEL}
          </Button>
          <Button
            className={{ root: 'px-8' }}
            data-testid="submit-button"
            onClick={handleSubmit}
            variant="primary"
          >
            {FCT.REPORTING.REPORT_ISSUE.ACTIONS.SUBMIT}
          </Button>
        </div>
      )}
    </div>
  );
}

export { ReportFormActions };

/* eslint-disable no-console */
import { FCT } from '@/i18n';

import { TabManagementOptions } from './header.model';

// This is a feature that allows the user to close all the tabs opened by FCT
export const initializeTabManagement = ({ onClose, studyId }: TabManagementOptions) => {
  if (!studyId) {
    return null;
  }

  // Check if BroadcastChannel is supported
  if (!('BroadcastChannel' in window)) {
    console.warn('BroadcastChannel is not supported in this browser');

    return null;
  }

  // Create broadcast channel for this study
  const channel = new BroadcastChannel(`prenuvo-fct-study-${studyId}`);
  const openedWindows: Window[] = [];

  // Override window.open to track opened windows
  const originalWindowOpen = window.open;

  window.open = function customWindowOpen(url, ...args) {
    const newWindow = originalWindowOpen.call(window, url, ...args);

    if (newWindow) {
      openedWindows.push(newWindow);
    }

    return newWindow;
  };

  // Listen for close messages
  channel.onmessage = (event) => {
    if (event.data.type === 'CLOSE_STUDY' && event.data.studyId === studyId) {
      window.close();
    }
  };

  // Handle window being closed
  const handleBeforeUnload = () => {
    // Close all opened windows
    openedWindows.forEach((win) => {
      try {
        win.close();
      } catch (e) {
        console.warn('Failed to close window:', e);
      }
    });

    // Broadcast the close message
    channel.postMessage({
      studyId,
      type: 'CLOSE_STUDY',
    });

    onClose?.();
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  // Return cleanup function
  return () => {
    window.open = originalWindowOpen;
    window.removeEventListener('beforeunload', handleBeforeUnload);
    channel.close();
  };
};

export const formatTimestamp = (timestamp: number) => {
  if (timestamp === 0) {
    return FCT.REPORTING.HEADER.UNKNOWN.DATE;
  }

  const date = new Date(timestamp * 1000);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${year}-${month}-${day}`;
};

export const formatMonthDayYear = (input: number | string): string => {
  if (input === 0 || !input) {
    return 'Unknown Date';
  }

  let date: Date;

  if (typeof input === 'number') {
    date = new Date(input < 10000000000 ? input * 1000 : input);
  } else {
    try {
      if (input.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = input.split('-').map(Number);

        date = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));
      } else {
        date = new Date(input);
      }
    } catch (e) {
      return 'Unknown Date';
    }
  }

  if (Number.isNaN(date.getTime())) {
    return 'Unknown Date';
  }

  const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  const day = String(date.getUTCDate());
  const year = String(date.getUTCFullYear());

  return `${month} ${day}, ${year}`;
};

export const calculateAge = (dob: string): number => {
  const today = new Date();
  const dobDate = new Date(dob);
  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDiff = today.getMonth() - dobDate.getMonth();
  const dayDiff = today.getDate() - dobDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

import axios from 'axios';

import { CONFIG } from '@/core/config';

import { errorInterceptor } from './utils';

const APPS = axios.create({ baseURL: CONFIG.APPS_SERVER, withCredentials: true });
const FCT = axios.create({ baseURL: CONFIG.FCT_SERVER, withCredentials: true });
const HI = axios.create({ baseURL: CONFIG.HI_SERVER, withCredentials: true });
const PII = axios.create({ baseURL: CONFIG.PII_SERVER, withCredentials: true });

// Bind the request interceptors to the axios api instances
APPS.interceptors.response.use((response) => response, errorInterceptor);
FCT.interceptors.response.use((response) => response, errorInterceptor);
HI.interceptors.response.use((response) => response, errorInterceptor);
PII.interceptors.response.use((response) => response, errorInterceptor);

export const fetcher = { APPS, FCT, HI, PII };

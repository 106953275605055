import { create } from 'zustand';

import { HiStudyState } from './use-hi-study.model';

export const useHiStudyStore = create<HiStudyState>((set) => ({
  isScanDetailLoading: false,
  medicalHistory: null,
  scanDetails: {
    machineID: '',
    patientID: '',
    scanDate: 0,
    skuID: '',
    technique: null,
  },
  setIsScanDetailLoading: (isScanDetailLoading) => set({ isScanDetailLoading }),
  setMedicalHistory: (medicalHistory) => set({ medicalHistory }),
  setScanDetails: (scanDetails) => set({ scanDetails }),
}));

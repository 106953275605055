import { InformationCircle, QuestionCircle } from '@prenuvo/halo-icon';

import { FormStructure } from '@/api';
import { NECK_DESIRED_ORDER } from '@/api/mocks/constants';
import { FormStructureSummary } from '@/store';

import { NAV_ICON_MAP, NavIconKey } from '../constant';
import { NavListProps } from '../model';

export const transformStructureToNavList = (data: FormStructure): FormStructureSummary => {
  const transformedStructure: NavListProps[] = data?.structure?.map((item) => {
    const processedItem = { ...item };

    if (processedItem.key === 'neck' && processedItem.sub) {
      processedItem.sub = [...processedItem.sub].sort(
        (a, b) => NECK_DESIRED_ORDER.indexOf(a.key) - NECK_DESIRED_ORDER.indexOf(b.key),
      );
    }

    return {
      key: processedItem.key,
      disabled: processedItem.disabled,
      icon: NAV_ICON_MAP[processedItem.key as NavIconKey] || QuestionCircle,
      progress: null,
      sub: processedItem.sub,
      title: processedItem.value,
    };
  });

  const ReasonForScanOption = {
    key: 'reason_for_scan',
    icon: InformationCircle,
    progress: null,
    title: 'Reason for a scan',
  };

  const favoriteOption = {
    key: 'favorite',
    icon: NAV_ICON_MAP.favorite,
    progress: null,
    title: 'Final Impressions',
  };

  const keyImagesOption = {
    key: 'key_images',
    icon: NAV_ICON_MAP.key_images,
    progress: null,
    title: 'Key Images',
  };

  const keyImagesRetrieveOption = {
    key: 'retrieve_key_images',
    icon: NAV_ICON_MAP.retrieve_key_images,
    progress: null,
    title: 'Retrieve key images',
  };

  transformedStructure?.push(keyImagesRetrieveOption, favoriteOption, keyImagesOption);
  transformedStructure?.unshift(ReasonForScanOption);

  return {
    ...data,
    structure: transformedStructure,
  };
};

export const transformSnakeCaseToPascalCase = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const transformSentenceToKebabCase = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .replace(/[\s,.&]+/g, '-')
    .replace(/[^a-z0-9-]/g, '');

import { FCT } from '@/i18n';

export const SPONDYLOARTHROPATHY_CONDITIONS = [
  'spondyloarthropathy_of_the_cervical_spine',
  'spondyloarthropathy_of_the_thoracic_spine',
  'spondyloarthropathy_of_the_lumbar_spine',
];

export const SPONDYLOARTHROPATHY_LABELS: {
  [key: string]: string;
} = {
  clinical_notes_placeholder:
    FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.LABELS.CLINICAL_NOTES_PLACEHOLDER,
  input_placeholder: FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.LABELS.INPUT_PLACEHOLDER,
  spondyloarthropathy_of_the_cervical_spine:
    FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.LABELS
      .SPONDYLOARTHROPATHY_OF_THE_CERVICAL_SPINE,
  spondyloarthropathy_of_the_lumbar_spine:
    FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.LABELS
      .SPONDYLOARTHROPATHY_OF_THE_LUMBAR_SPINE,
  spondyloarthropathy_of_the_thoracic_spine:
    FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.LABELS
      .SPONDYLOARTHROPATHY_OF_THE_THORACIC_SPINE,
};

export const SPONDYLOARTHROPATHY_OUTPUT_LABELS: {
  [key: string]: string;
} = {
  spondyloarthropathy_of_the_cervical_spine:
    FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.OUTPUT_LABELS
      .SPONDYLOARTHROPATHY_OF_THE_CERVICAL_SPINE,
  spondyloarthropathy_of_the_lumbar_spine:
    FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.OUTPUT_LABELS
      .SPONDYLOARTHROPATHY_OF_THE_LUMBAR_SPINE,
  spondyloarthropathy_of_the_thoracic_spine:
    FCT.REPORTING.ENTRY.ORGAN.FINDING.SPONDY_CONDITIONS.OUTPUT_LABELS
      .SPONDYLOARTHROPATHY_OF_THE_THORACIC_SPINE,
};

export const SPONDY_FINDING = {
  conditionTemplate: {
    condition: 'Spondyloarthropathy',
    conditionDetail: [],
    normalStatementOutcome: {},
    organ: 'spine',
    uuid: 'Spondyloarthropathy-conditions',
  },
  renderedSummary: {
    actionDetails: '',
    actionSummary: '',
    additionalRecommendations: null,
    clinicalNotes: null,
    csdScore: 0,
    findingDetails: 'We detected evidence of spondyloarthropathy.',
    findingSummary: '',
    keyImages: [],
    newFinding: false,
    oncoradsScore: 0,
    reportFindings: [],
    reportFindingsUuids: [],
    reportUuid: '',
    status: '',
    uuid: 'Spondyloarthropathy-rendered-summary',
  },
};

import { useMutation } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/api';
import { makeApiRequest } from '@/api/utils/make-api-request/makeApiRequest';
import { useKeyImagesStore } from '@/store';

import { SeriesProps, SeriesRetrieveResponse } from './useSeries.model';

export const useSeries = () => {
  const { setIsGlobalKILoading, setIsKeyImagesError } = useKeyImagesStore();

  const mutation = useMutation({
    mutationFn: async ({ studyId }: SeriesProps) => {
      const novaradResponse = await makeApiRequest<SeriesRetrieveResponse>(
        'HI',
        API_ENDPOINTS.dicom.deleteSeries,
        'POST',
        {
          key: 'novarad_mark-up',
          studyID: studyId,
        },
      );
      const keyImagesResponse = await makeApiRequest<SeriesRetrieveResponse>(
        'HI',
        API_ENDPOINTS.dicom.deleteSeries,
        'POST',
        {
          key: 'key_images',
          studyID: studyId,
        },
      );

      const seriesRetrieve = await makeApiRequest<SeriesRetrieveResponse>(
        'HI',
        API_ENDPOINTS.dicom.seriesRetrieve,
        'POST',
        {
          series_desc_list: ['novarad_mark-up', 'key_images'],
          study_id: studyId,
        },
      );

      return {
        keyImages: keyImagesResponse.data,
        novaradMarkUp: novaradResponse.data,
        series: seriesRetrieve.data,
      };
    },
    onError: () => {
      setIsGlobalKILoading(false);
      setIsKeyImagesError(true);
    },
  });

  return mutation;
};

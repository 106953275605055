import { PageLoader } from '@prenuvo/halo-foundation';

import { PageLoaderGif } from '@/assets/icons';

export function Loading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <PageLoader src={PageLoaderGif} variant="dark" />
    </div>
  );
}

import { create } from 'zustand';

import { MedicalHistoryState } from './medical-history.model';

export const useMedicalHistoryStore = create<MedicalHistoryState>((set, get) => ({
  getMedicalHistory: (organ: string) => get().medicalHistory[organ] || '',
  medicalHistory: {},
  setMedicalHistory: (medicalHistory: { [key: string]: string }) => {
    set({ medicalHistory });
  },
}));

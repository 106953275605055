import { JiraSubmission, JiraSubmitter } from '../model/jira-submitter.model';

export type JiraDescriptionArgs = {
  description: string;
  email: string;
  name: string;
  prenuvoID: string;
};

const formatJiraDescription = ({
  description,
  email,
  name,
  prenuvoID,
}: JiraDescriptionArgs): string => `${description}

Link: ${window.location.href}

Submitted by:
Name: ${name}
Email: ${email}
Prenuvo ID: ${prenuvoID}
`;
const createJiraSubmitter =
  ({ email, name, prenuvoID }: JiraSubmitter) =>
  ({ description, issueTypeName, projectKey, title }: JiraSubmission) => {
    const formattedDescription = formatJiraDescription({
      description,
      email,
      name,
      prenuvoID,
    });

    return {
      description: formattedDescription,
      issuetype: { name: issueTypeName },
      project: {
        key: projectKey,
      },
      summary: title,
    };
  };

export { createJiraSubmitter };

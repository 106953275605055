import {
  Adrenals,
  Ankles,
  Bladder,
  Bowel,
  Brain,
  Breast,
  Esophagus,
  Gallbladder,
  Heart,
  Image,
  ImageDownload,
  InformationCircle,
  Kidneys,
  Knees,
  Liver,
  Lungs,
  Neck,
  Ovaries,
  Pancreas,
  Pelvis,
  Prostate,
  SacroliacJoints,
  Shoulders,
  Sinus,
  Skleleton,
  Spine,
  Spleen,
  Star,
  Stomach,
  Uterus,
} from '@prenuvo/halo-icon';

export const NAV_ICON_MAP = {
  adrenals: Adrenals,
  ankles: Ankles,
  bladder_and_ureters: Bladder,
  bony_skeleton: Skleleton,
  bowel: Bowel,
  brain: Brain,
  breasts: Breast,
  esophagus: Esophagus,
  favorite: Star,
  gallbladder_and_biliary_system: Gallbladder,
  heart_and_great_vessels: Heart,
  key_images: Image,
  kidneys: Kidneys,
  knees: Knees,
  liver: Liver,
  lungs_and_mediastinum: Lungs,
  neck: Neck,
  ovaries: Ovaries,
  pancreas: Pancreas,
  pelvis_and_hips: Pelvis,
  prostate: Prostate,
  reason_for_scan: InformationCircle,
  retrieve_key_images: ImageDownload,
  sacroiliac_joints: SacroliacJoints,
  shoulders: Shoulders,
  sinuses_and_mastoids: Sinus,
  spine: Spine,
  spleen: Spleen,
  stomach: Stomach,
  uterus: Uterus,
} as const;

export type NavIconKey = keyof typeof NAV_ICON_MAP;

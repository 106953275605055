/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS, makeApiRequest, StudiesResponse } from '@/api';
import { TEMP_QA_ENABLED } from '@/api/mocks/constants';
import {
  getPreviousStudyIds,
  StudyResult,
  usePatientStudiesStore,
} from '@/store/use-patient-studies';

export const usePatientStudies = (
  patientId: string,
  studyId: string | undefined,
  scanDate: number,
) => {
  const hasPatientStudiesFetched = useRef(false);
  const [previousStudyIds, setPreviousStudyIds] = useState<StudyResult[]>([]);
  const { setPatientStudies } = usePatientStudiesStore();
  const url = TEMP_QA_ENABLED
    ? `${API_ENDPOINTS.study.getStudy}/${studyId}`
    : `${API_ENDPOINTS.study.getStudy}?users=${patientId}&status=scanned`;

  const has = {
    patientId: patientId !== undefined,
    studyId: studyId !== undefined,
  };

  const enabled =
    (TEMP_QA_ENABLED ? has.studyId : has.patientId) && !hasPatientStudiesFetched.current;

  const fetchPatientStudies = () => makeApiRequest<StudiesResponse>('APPS', url, 'GET');

  const {
    data: patientStudiesData,
    error,
    isLoading,
  } = useQuery({
    enabled,
    queryFn: fetchPatientStudies,
    queryKey: ['patientStudies', patientId],
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (patientStudiesData) {
      setPatientStudies(patientStudiesData.data.studies);
      hasPatientStudiesFetched.current = true;

      if (patientStudiesData?.data?.studies?.length > 0) {
        const previousIds = getPreviousStudyIds(patientStudiesData.data.studies, studyId, scanDate);

        setPreviousStudyIds(previousIds);
      } else {
        console.log('No patient studies found');
      }
    }
  }, [patientStudiesData, hasPatientStudiesFetched]);

  return { error, isLoading, previousStudyIds };
};

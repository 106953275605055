import { conditionTemplate } from './condition-template.query';
import { formStructure } from './form-structure.query';
import { keyImage } from './key-image.query';
import { renderedSummary } from './rendered-summary.query';
import { report } from './report.query';
import { reportFinding } from './report-finding.query';
import { reportIssue } from './report-issue.query';
import { reportOrgan } from './report-organ.query';
import { reportPdf } from './report-pdf.query';

export const fct = {
  conditionTemplate,
  formStructure,
  keyImage,
  renderedSummary,
  report,
  reportFinding,
  reportIssue,
  reportOrgan,
  reportPdf,
};

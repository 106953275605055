import { createContext, ReactNode } from 'react';

import { useReportIssue } from '../report-issue.hook';

const ReportIssueContext = createContext<ReturnType<typeof useReportIssue> | undefined>(undefined);

type ReportIssueProviderProps = {
  children: ReactNode;
};

export function ReportIssueProvider({ children }: ReportIssueProviderProps) {
  const reportIssue = useReportIssue();

  return <ReportIssueContext.Provider value={reportIssue}>{children}</ReportIssueContext.Provider>;
}

ReportIssueProvider.Context = ReportIssueContext;

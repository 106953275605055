import { LogsInitConfiguration } from '@datadog/browser-logs';
import { RumInitConfiguration } from '@datadog/browser-rum';
import { BaseDDConfig, Env } from '@prenuvo/nimbus-logger';

import { CONFIG } from '@/core/config';

export const ddLogConfig: LogsInitConfiguration = {
  clientToken: CONFIG.DATADOG.TOKEN,
  env: CONFIG.ENV,
  forwardConsoleLogs: ['info', 'warn', 'error'],
  forwardErrorsToLogs: true,
  service: CONFIG.NAME,
  trackSessionAcrossSubdomains: CONFIG.DATADOG.SUBDOMAIN_TRACKING,
  useSecureSessionCookie: CONFIG.DATADOG.USE_SECURE_SESSION,
  version: CONFIG.VERSION,
};

export const ddRumConfig: RumInitConfiguration = {
  applicationId: CONFIG.DATADOG.APP_ID,
  clientToken: CONFIG.DATADOG.TOKEN,
  defaultPrivacyLevel: 'mask-user-input',
  env: CONFIG.ENV,
  service: CONFIG.NAME,
  sessionReplaySampleRate: 20,
  sessionSampleRate: 100,
  trackLongTasks: true,
  trackResources: true,
  trackSessionAcrossSubdomains: CONFIG.DATADOG.SUBDOMAIN_TRACKING,
  trackUserInteractions: true,
  useSecureSessionCookie: CONFIG.DATADOG.USE_SECURE_SESSION,
  version: CONFIG.VERSION,
};

export const baseConfig = {
  appId: ddRumConfig.applicationId,
  env: Env[ddLogConfig.env as keyof typeof Env],
  service: ddLogConfig.service,
  token: ddRumConfig.clientToken,
  version: ddLogConfig.version,
} as BaseDDConfig;

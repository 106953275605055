import { useParams } from 'react-router-dom';
import { Button, IconButton, Popover, Typography } from '@prenuvo/halo-foundation';
import { ResetView } from '@prenuvo/halo-icon';

import { api } from '@/api';
import { FCT } from '@/i18n';

/**
 * Reset Report Button
 *
 * Temporary button to reset the report to the initial state.
 * This is a temporary solution until we have a proper way to reset the report.
 * TODO: Remove this button once we enter production.
 */
export function ResetReportButton() {
  const { id: studyId } = useParams<{ id: string }>();
  const { mutate: reportMutation } = api.fct.report.mutation.usePatchReport();

  const onResetReport = () => {
    reportMutation(
      {
        payload: {
          status: 'deprecated',
          studyId: studyId!,
        },
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
      },
    );
  };

  return (
    <Popover
      trigger={
        <IconButton
          aria-label={FCT.REPORTING.HEADER.ACTIONS.RESET_REPORT}
          data-testid="reset-report-button"
          icon={ResetView}
          size="sm"
          variant="text"
        />
      }
    >
      <Typography as="p">{FCT.REPORTING.HEADER.RESET_REPORT.DESCRIPTION}</Typography>
      <div className="mt-4 flex justify-end">
        <Button onClick={onResetReport} size="sm" variant="outlined">
          {FCT.REPORTING.HEADER.ACTIONS.RESET_REPORT}
        </Button>
      </div>
    </Popover>
  );
}
